@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - contact
 *
 */

.required {
    color: #f51d3b;
}

.contact__main {}

.contact__body {}

.contact__form__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.contact__form__container__wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.contact__form {}

.contact__form__required {
    margin-bottom: 25px;
}

.contact__form__content {
    display: flex;
    flex-wrap: wrap;

    dt {
        width: 100%;
        margin-bottom: 10px;
    }

    dd {
        width: 100%;
        margin-bottom: 20px;

        input[type="text"] {
            width: 100%;
            border: 1px solid #ddd;
        }

        .contact__form__email__confirmation {
            margin-top: 10px;
        }

        textarea {
            width: 100%;
            height: 15em;
            border: 1px solid #ddd;
        }

        a {
            color: var(--global--color-primary);

            &:hover {
                opacity: 0.6;
            }
        }

        .contact__form__inquiry__agree {
            margin-top: 15px;
        }

        input[type="checkbox"] {
            margin-right: 10px;
            cursor: pointer;
        }

        label {
            cursor: pointer;
        }
    }

    @include global.media(touch) {
        dt {
            width: 30%;
        }

        dd {
            width: 70%;
        }
    }
}

.page__content__input {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}