@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - business
 *
 */

.business__main {}

.business__body {}

.business__service__container {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--global--color-white);
    background-color: var(--global--color-black);
    margin-top: 100px;
}

.business__service__container__wrapper {
    width: 100%;
    max-width: 1400px;
    padding: 100px 0;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.business__service {}

.business__service__title {}

.business__service__content {
    margin-bottom: 80px;

    &:nth-child(2) {
        margin-top: 60px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.business__service__content__title {
    padding-left: 1em;
    text-indent: -1em;
    font-size: var(--global--font-size-xxl);
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 40px;
}

.business__service__content__first__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-top: 40px;

    @include global.media(touch) {
        flex-direction: row;
        justify-content: center;
    }
}


.business__service__content__first__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;

    a {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &:hover {
            img {
                opacity: 0.6;

            }

            .business__service__content__first__go-to-site {

                &::after {
                    width: 40px;
                    margin-right: -5px;
                }
            }
        }
    }
}

.business__service__content__first__title {
    font-size: var(--global--font-size-lg);
}

.business__service__content__first__go-to-site {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 0;
    border-bottom: 1px solid var(--global--color-white);
    cursor: pointer;
    color: var(--global--color-white);

    &::after {
        display: inline-block;
        content: "";
        width: 30px;
        height: 8px;
        margin-left: 30px;
        margin-right: 5px;
        border-bottom: 1px solid var(--global--color-white);
        border-right: 1px solid var(--global--color-white);
        transform: skew(45deg);
        transition: .3s;
    }

}

.business__service__content__first__description {
    margin-top: 40px;
}

.business__service__content__second__wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.business__service__content__youtube {
    width: 80%;
    aspect-ratio: 16 / 9;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }

    i {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 6vw;
        color: #333;
        transition: 0.3s;
    }

    &:hover {

        img {
            opacity: 0.6;

        }

        i {
            color: #eee;
        }
    }

    @include global.media(touch) {

        width: 30%;

        i {

            font-size: 40px;
        }
    }
}

.business__service__content__second__description {
    margin-top: 40px;
}

.business__service__content__second__wrap {
    margin-top: 30px;
}

.business__service__content__third__description {
    margin-top: 30px;
}

.business__service__content__third__wrap {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
}

.business__service__content__third__item {
    width: 100%;

    @include global.media(touch) {

        width: 30%;

    }
}

.business__service__content__third__title {
    font-weight: 700;
    font-size: var(--global--font-size-xl);
}

.business__service__content__third__text {
    margin-top: 20px;
}