@charset 'utf-8';

// Media Query
$width_tb: 560;
$width_touch: 1025;
$width_pc: 1239;
$width_wide: 1366;
$breakpoints: (sp : 'only screen and (max-width: ('($width_tb * 1px)' - 1px ))',
  tb : 'only screen and (min-width: '($width_tb * 1px)')',
  touch : 'only screen and (min-width: '($width_touch * 1px)')',
  pc : 'only screen and (min-width: '($width_pc * 1px)')',
  wide: 'only screen and (min-width: '($width_wide * 1px)')'
);

@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

// Z-index
$layer: ( // modalbtn          : 100,
  // modal             : 90,
  overlay : 30,
  gnav-trigger : 21,
  gnav : 20,
  gnav-overlay : 19,
  fixedbtn : 10,
  stick : 5,
  on-bg : 1,
  reset : 0,
  bg : -1);

@mixin z-index($key) {
  z-index: map-get($layer, $key);
}

// Touch Device
@mixin touch($bool: true) {
  @if $bool {
    [data-touch="true"] & {
      @content;
    }
  }

  @else {
    [data-touch="false"] & {
      @content;
    }
  }
}

// Hover
@mixin hover($parent: '') {
  @if $parent=='' {
    &:hover {
      [data-touch="false"] & {
        @content;
      }
    }
  }

  @else {
    [data-touch="false"] #{$parent}:hover & {
      @content;
    }
  }
}

// Clearfix
@mixin cf {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

// Replace
@mixin replace($original, $replacement) {
  @at-root {
    #{selector-replace(&, $original, $replacement)} {
      @content;
    }
  }
}

// Unify
@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

// Border Decoration by SVG
@mixin svg-border($width: 2px, $color: var(--global--color-border), $style: dotted) {
  color: $color;
  stroke-width: $width;
  stroke-dasharray: #{if($style == dotted, '2 8', 'none' )};
}