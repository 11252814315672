@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - home
 *
 */

/* Main */
.home__main {}

.home__main__inner {}

/* Section */
.home__section {
  .section__inner {}

  .section__header {}

  .section__title {}

  .section__body {}

  .section__description {}

  .section__footer {}
}

.home__body {
  font-size: var(--global--font-size-xs);

  @include global.media(tb) {
    font-size: var(--global--font-size-md);
  }
}

.home__main-visual {
  position: relative;
  min-height: 80vw;

  @include global.media(touch) {
    min-height: 0;
  }
}

.home__main-visual__img {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.home__main-visual__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home__main-visual__blind-1 {
  position: absolute;
  top: 0;
  right: calc(50vh + -50vw);
  width: 10000px;
  height: 100%;
  background-color: var(--global--color-white);
  transform: skewX(-45deg);
  z-index: 1;

  @include global.media(tb) {
    right: calc(50vh + 0vw);
  }

  @include global.media(pc) {
    right: calc(50vh + 25vw);
  }
}

.home__main-visual__blind-2 {
  position: absolute;
  top: 0;
  left: calc(50vh + 80vw);
  width: 10000px;
  height: 100%;
  background-color: var(--global--color-white);
  transform: skewX(-45deg);
  z-index: 1;
}

.home__main-visual__copy {
  mix-blend-mode: difference;
  position: absolute;
  top: 25vh;
  left: 5vw;
  color: var(--global--color-white);
  font-weight: 900;
  font-size: 14vw;
  line-height: 1.4;
  cursor: default;
  font-weight: 700;
  z-index: 2;

  @include global.media(touch) {
    font-size: 14vh;
  }

  @include global.media(pc) {
    top: 20vh;
    font-size: 16vh;
  }
}

.home__main-visual__scroll {
  mix-blend-mode: difference;
  position: absolute;
  bottom: 70px;
  right: 3vw;
  width: 140px;
  height: 140px;
  border: 1px solid var(--global--color-white);
  color: var(--global--color-white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  z-index: 10;

  @include global.media(pc) {
    right: auto;
    bottom: 60px;
    left: 3vw;
    width: 200px;
    height: 200px;
  }

  &::after {
    display: block;
    content: "";
    position: absolute;
    top: 75%;
    right: 50%;
    width: 15px;
    height: 75px;
    border-bottom: 1px solid var(--global--color-white);
    border-right: 1px solid var(--global--color-white);
    transform: skewY(45deg);
    animation: scroll-angle 2s ease-in-out infinite;

  }

  &:hover {
    background-color: #eeeeee10;
  }
}

@keyframes scroll-angle {
  0% {
    top: 71%;
  }

  50% {
    top: 79%;
  }

  100% {
    top: 71%;
  }
}

.home__top-news {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.home__top-news__content {
  margin: 0 50px;
  max-width: 800px;
}

.home__top-news__item {
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    color: var(--global--color-black);
    opacity: 0.6;
  }
}

.home__top-news__tag {
  position: relative;
  font-size: var(--global--font-size-md);
  margin-right: 30px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    content: "";
    width: 2px;
    height: 40px;
    background-color: var(--global--color-primary);
    transform: translateY(-50%) translateX(-50%) skew(-45deg);
  }

  span {
    position: relative;
    z-index: 1;
  }

  @include global.media(touch) {
    margin-right: 40px;
  }
}

.home__top-news__item__wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  font-size: var(--global--font-size-md);

  @include global.media(touch) {
    font-size: var(--global--font-size-xl);

  }
}

.home__top-news__tilte {
  margin-right: 30px;

  @include global.media(touch) {
    margin-right: 40px;
  }
}

.home__top-news__text {
  line-height: 1.2;
}

.home__more-btn {
  display: inline-block;
  margin-top: 30px;
  padding: 5px 0;
  border-bottom: 1px solid var(--global--color-black);
  cursor: pointer;

  &::after {
    display: inline-block;
    content: "";
    width: 30px;
    height: 8px;
    margin-left: 30px;
    margin-right: 5px;
    border-bottom: 1px solid var(--global--color-black);
    border-right: 1px solid var(--global--color-black);
    transform: skew(45deg);
    transition: .3s;
  }

  &:hover {
    opacity: 0.6;
    color: var(--global--color-black);

    &::after {
      width: 40px;
      margin-right: -5px;
    }
  }
}

.home__message {
  position: relative;
  width: 100%;
  min-height: 90vh;
  overflow: hidden;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .home__message__content {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 20px;

    @include global.media(touch) {
      margin: 0 50px 0 50px;
      padding: 0;
      width: auto;
      max-width: 1400px;
    }
  }

  .home__message__title {
    font-size: var(--global--font-size-xxl);
    line-height: 1.6;

    @include global.media(touch) {
      width: 70%;
      font-size: var(--global--font-size-xxxl);
    }
  }

  .home__message__text {
    margin-top: 30px;

    @include global.media(touch) {
      width: 50%;
    }
  }

  .home__message__img {
    margin-top: 30px;

    @include global.media(touch) {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      margin-top: 0;

    }
  }

  .home__message__img-src {
    position: relative;
    height: 70vw;
    width: calc(70vw * 0.7);
    transform: skewX(-45deg);
    overflow: hidden;
    margin: 0 auto;

    &.first {
      z-index: 2;

      &::after {
        position: absolute;
        top: 0;
        right: -75%;
        content: "";
        display: block;
        width: 260%;
        height: 100%;
        background-image: url(../img/home_message_img_first.png);
        background-size: cover;
        transform: skewX(45deg);
      }
    }

    &.second {
      z-index: -1;
      margin-top: calc(-70vw * 0.5);

      &::after {
        position: absolute;
        top: 0;
        right: -75%;
        content: "";
        display: block;
        width: 260%;
        height: 100%;
        background-image: url(../img/home_message_img_second.png);
        background-size: cover;
        transform: skewX(45deg);
      }
    }

    @include global.media(touch) {
      height: 60vh;
      width: calc(60vh * 0.7);

      &.second {
        margin-top: calc(-60vh * 0.5);
      }

    }
  }
}

.home__budiness-and-career {
  background-color: #DEDEDE;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30vh;
    background: linear-gradient(#fff, #dedede);
  }

  @include global.media(touch) {
    margin-top: 20vh;
  }
}

.home__budiness {
  min-height: max(100vh, 160vw);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;

  @include global.media(touch) {
    min-height: 100vh;
  }
}

.home__budiness__bg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 28vh;
  font-weight: 900;
  color: #fff;
  line-height: 1.1;
  opacity: 0.1;
  transform: translateY(-50%) translateX(-50%);
  pointer-events: none;
}

.home__budiness__content {
  margin: 50px 0;
  padding: 0 50px;
  width: 100%;
  max-width: 1400px;
}

.home__budiness__module {
  width: 100%;

  @include global.media(touch) {
    width: 50%;
  }
}

.home__budiness__title {
  position: relative;
  font-size: 12vw;
  font-weight: 700;

  @include global.media(touch) {
    font-size: 5rem;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 2rem;
    content: "";
    width: 3px;
    height: 10rem;
    background-color: var(--global--color-primary);
    transform: translateY(-50%) skew(-45deg);
  }

  span {
    position: relative;
    z-index: 1;
  }
}

.home__budiness__text {
  @include global.media(tb) {
    width: 60%;
  }

  @include global.media(pc) {
    width: 100%;
  }
}

.home__career {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: var(--global--color-white);

  @include global.media(touch) {
    justify-content: center;
    align-items: center;
    margin-top: 10vh;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 1000%;
    height: 1000%;
    background-color: var(--global--color-black);
    transform-origin: left top;
    transform: rotate(-45deg);

    @include global.media(touch) {
      top: 70vh;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-color: var(--global--color-black);

    @include global.media(touch) {
      top: 70vh;
    }
  }
}

.home__career__content {
  margin: 0 0 50px;
  padding: 0 50px;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: flex-start;
  z-index: 10;
  margin-top: 10vh;

  @include global.media(touch) {
    justify-content: flex-end;
  }
}

.home__career__module {
  width: 100%;

  @include global.media(touch) {
    width: 50%;
  }

  .home__more-btn {
    border-bottom-color: var(--global--color-white);
    color: var(--global--color-white);

    &::after {
      border-bottom-color: var(--global--color-white);
      border-right-color: var(--global--color-white);
    }
  }
}

.home__career__title {
  position: relative;
  font-size: 12vw;
  font-weight: 700;

  @include global.media(touch) {
    font-size: 5rem;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 2rem;
    content: "";
    width: 3px;
    height: 10rem;
    background-color: var(--global--color-primary);
    transform: translateY(-50%) skew(-45deg);
  }

  span {
    position: relative;
    z-index: 1;
  }
}

.home__career_text {}

.home__career__person__contner {
  padding: 30px;
  width: 100%;
  max-width: 1400px;
  z-index: 10;
  margin-bottom: 50px;

  @include global.media(touch) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.home__career__person {
  background-color: var(--global--color-white);
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  color: var(--global--color-text);

  &:last-child {
    margin-bottom: 0;
  }

  @include global.media(touch) {
    flex-direction: row;
    align-items: center;
  }
}

.home__career__person__img {
  width: 100%;

  @include global.media(touch) {
    width: 500px;
  }

  img {
    width: 100%;
  }
}

.home__career__person__content {
  padding: 20px;

  @include global.media(touch) {
    margin-left: 30px;
  }
}

.home__career__person__title {
  font-size: var(--global--font-size-xl);
  line-height: 1.2;
  font-weight: 700;

  @include global.media(touch) {
    font-size: var(--global--font-size-xxl);
  }
}

.home__career__person__text {
  margin-top: 15px;
}

.home__bottom-photo {}