@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - component
 *
 */

.page__title__container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.page__title__container__wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 20px;
    margin-top: 100px;

    @include global.media(touch) {
        margin: 100px 50px 0;
    }
}

.page__title {
    position: relative;
    font-size: min(16vw, 5rem);
    font-weight: 700;
    line-height: 1.2;

    @include global.media(touch) {
        font-size: 5rem;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 2rem;
        content: "";
        width: 3px;
        height: 10rem;
        background-color: var(--global--color-primary);
        transform: translateY(-50%) skew(-45deg);
    }

    span {
        position: relative;
        z-index: 1;
    }
}

.page__title__description {
    font-size: var(--global--font-size-lg);
    margin-top: 30px;
}

.page__content__title {
    font-size: var(--global--font-size-xxxxl);
    font-weight: 700;
}

.page__content__btn {
    background-color: var(--global--color-primary);
    border-radius: 100px;
    color: var(--global--color-white);
    padding: 8px 50px;

    i {
        margin-right: 10px;
    }

    &:hover {
        color: var(--global--color-white);
        opacity: 0.6;
    }
}