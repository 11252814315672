@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - privacy
 *
 */

.privacy__main {}

.privacy__body {}

.privacy__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.privacy__wrapper {
    width: 100%;
    max-width: 1000px;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.privacy {
    font-size: var(--global--font-size-sm);
}

.privacy__content {
    margin-top: 60px;

    &:first-child {
        margin-top: 0;
    }
}

.privacy h2 {
    font-size: var(--global--font-size-xxl);
    margin: 20px 0;
    line-height: 1.2;
    font-weight: 700;

    @include global.media(touch) {
        font-size: var(--global--font-size-xxxl);
    }
}

.privacy h3 {
    font-size: var(--global--font-size-xl);
    margin-top: 40px;
    margin-bottom: 20px;
    line-height: 1.2;
}

.privacy h4 {
    font-size: var(--global--font-size-lg);
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 1.2;
    display: inline-block;
}

.privacy p {
    margin-bottom: 15px;
}

.privacy a {
    color: var(--global--color-primary);

    &:hover {
        opacity: 0.6;
    }
}

.privacy .section-footer {
    text-align: right;
    margin: 30px auto;
}

.privacy time {
    display: block;
    margin: 10px auto;
}

.box-table li {
    text-indent: -10px;
    padding-left: 20px;

    &::before {
        content: '・';
        display: inline-block;
        width: 10px;
    }
}

.box-table dl {
    @include global.media(touch) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.box-table dl dt {
    padding: 5px 2px;
}

.box-table dl dt::after {
    content: '';
    display: block;
    width: 22px;
    height: 2px;
    background: #14b09b;
    margin: 0;
}

.box-table dl dt {
    @include global.media(touch) {
        width: calc(25% - 10px);
        margin: 5px 0;
    }

    &::after {
        display: none;
    }
}

.box-table dl dd {
    padding: 5px 0;
    margin-bottom: 10px;
}

.box-table dl dd {
    @include global.media(touch) {
        width: calc(75% - 15px);
        margin: 5px 0 5px 15px;
    }
}