@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Header
 *
 */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--global--color-bg);
  z-index: 20;
  transition: 0.5s;

  .header__inner {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    transition: 0.5s;

    @include global.media(pc) {
      padding: 10px 60px;
    }
  }

  .header__logo__link {
    display: block;
    width: min(40vw, 180px);
    transition: 0.5s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .nav__global__list {
    font-size: var(--global--font-size-md);

    @include global.media(pc) {
      transition: 0.5s;
    }
  }

  &.change-color {
    background-color: #fdfdfd80;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-bottom: none;

    &.active {
      background-color: var(--global--color-bg);
    }

    .header__inner {
      padding: 5px 20px;
    }

    .header__logo__link {
      width: min(25vw, 140px);
    }

    .nav__global__list {
      font-size: var(--global--font-size-sm);
    }
  }
}

.header__logo {
  flex-grow: 1;
}

.header__logo__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.header__nav {}

/*==================================================
　5-2-1 3本線が×に
===================================*/
/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.header__nav__trigger {
  position: relative;
  /*ボタン内側の基点となるためrelativeを指定*/
  cursor: pointer;
  width: 44px;
  height: 44px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  @include global.media(pc) {
    display: none;
  }
}

/*ボタン内側*/
.header__nav__trigger span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  position: absolute;
  left: 10px;
  height: 2px;
  border-radius: none;
  background: #323232;
  width: 55%;
}

.header__nav__trigger span:nth-of-type(1) {
  top: 13px;
}

.header__nav__trigger span:nth-of-type(2) {
  top: 21px;
}

.header__nav__trigger span:nth-of-type(3) {
  top: 29px;
}

/*activeクラスが付与されると線が回転して×に*/
.header__nav__trigger.active span:nth-of-type(1) {
  top: 15px;
  left: 10px;
  transform: translateY(6px) rotate(-45deg);
  width: 55%;
}

.header__nav__trigger.active span:nth-of-type(2) {
  opacity: 0;
  /*真ん中の線は透過*/
}

.header__nav__trigger.active span:nth-of-type(3) {
  top: 27px;
  left: 10px;
  transform: translateY(-6px) rotate(45deg);
  width: 55%;
}

//トップメニュー
.nav__global__list {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--global--color-bg);
  color: #323232;
  z-index: 10;
  width: 100%;
  padding: 0 5% 20px;

  .menu-item {
    border-top: 1px solid #E4E4E4;
    padding: 15px 10px;
    position: relative;

    a {
      display: block;
      width: 100%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    i {
      margin-left: 5px;
    }
  }

  .menu-item:last-child {
    border-bottom: 1px solid #E4E4E4;
  }

  .menu-item::after {
    content: '';
    width: 10px;
    height: 10px;
    border-top: solid 2px var(--brand--color-rsv);
    border-right: solid 2px var(--brand--color-rsv);
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }

  @include global.media(pc) {
    background: none;
    display: flex;
    flex-direction: row;
    position: static;
    padding: 0;

    .menu-item {
      border: none;
    }

    .menu-item:last-child {
      border: none;
    }

    .menu-item::after {
      display: none;
    }

  }
}

//ページ内リンクの位置調整
.home__title-area[id] {
  &::before {
    content: "";
    display: block;
    padding-top: 80px;
    margin-top: -80px;
  }
}


//上に戻るボタン
.back-to-top {
  display: none;
  position: fixed;
  right: 5vh;
  bottom: 5vh;
  padding: 0;
  border-radius: 50%;
  display: inline-block;
  text-decoration: none;
  opacity: 0.7;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    width: 55px;
    height: 55px;
    background-color: #4abaa7;
    border-radius: 50%;
    z-index: -1;
    border: 1px solid #fff;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 0 10px;
    border-top: 4px solid #fff;
    border-left: 4px solid #fff;
    transform: rotate(45deg);
  }

  @include global.media(pc) {
    right: 10vh;
    bottom: 10vh;
  }
}