@charset "utf-8";

@use '../00-settings' as global;

// ----- Styling ---------------------
:root {
  @include global.media(wide) {
    --global--font-size-base: 17px;
  }
}
html {
  font-size: var(--global--font-size-base);
}
body {
  color: var(--global--color-text);
  font-size: var(--global--font-size-md);
  font-family: var(--global--font-family);
  line-height: var(--global--line-height);
  letter-spacing: var(--global--letter-spacing);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  font-size: var(--global--font-size-md);
}
a {
  text-decoration: none;
  transition: global.transition(color);
  color: var(--global--color-text-link);
  //@include global.hover {
  //  color: var(--global--color-text-link-hover);
  //}
  &:hover {
    color: var(--global--color-text-link-hover);
  }
}
em {
  font-style: normal;
}
ul, ol {
  padding: 0;
}
li {
  list-style: none;
}

@include global.media(tb) {
  :root {
    /* Heading */
    --heading--font-size-lg   : 4.8rem;
    --heading--font-size-md   : 3.2rem;
    --heading--font-size-sm   : 2.4rem;
    --heading--line-height    : 1.2;
    --heading--letter-spacing : .02rem;
  }
}