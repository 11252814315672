@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - company
 *
 */

.company__main {}

.company__body {
    overflow: hidden;
}

.company__what-we-do__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.company__what-we-do__container__wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.company__what-we-do__title {}

.company__what-we-do__sub-title {
    font-size: var(--global--font-size-xl);
    font-weight: 700;
    line-height: 1.4;
    margin-top: 10px;

    @include global.media(touch) {
        font-size: var(--global--font-size-xxl);
    }
}

.company__what-we-do__text {
    margin-top: 10px;
}

.company__what-we-do {}

.company__value__container {
    width: 100%;
    display: flex;
    justify-content: center;
    color: var(--global--color-white);
    margin-bottom: 100px;
    background-color: var(--global--color-black);
    flex-wrap: wrap;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -1000px;
        right: -1000px;
        width: 1000px;
        height: 1000px;
        background-color: #E9E9E9;
        transform: skewx(-45deg);
        z-index: -1;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -1000px;
        left: -1000px;
        width: 1000px;
        height: 1000px;
        background-color: #E9E9E9;
        transform: skewX(-45deg);
        z-index: -1;
    }

    @include global.media(touch) {
        &::before {
            //right: -70vw;
        }

        &::after {
            //left: -70vw;
        }
    }
}

.company__value__container__wrapper {
    width: 100%;
    max-width: 1400px;
    padding: 100px 0;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.company__value {}

.company__value__title {
    margin-bottom: 50px;
}

.company__value__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: -50px;

    @include global.media(touch) {
        flex-direction: row;
    }
}

.company__value__item {
    width: 100%;
    margin-bottom: 50px;

    @include global.media(touch) {
        width: 31%;
    }
}

.company__value__item__title {
    font-size: var(--global--font-size-xxl);
    font-weight: 700;
}

.company__value__item__text {
    margin-top: 20px;
}

.company__value__item__text-jp {
    margin-top: 15px;
}

.company__about-us__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

.company__about-us__container__wrapper {
    width: 100%;
    max-width: 1400px;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.company__about-us {}

.company__about-us__title {}

.company__about-us__content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    @include global.media(touch) {
        flex-direction: row;
    }
}

.company__about-us__item {
    font-size: var(--global--font-size-lg);
    width: 100%;
    order: 2;

    dl {
        display: block;
        flex-wrap: wrap;
    }

    dt {
        font-weight: 700;
        width: 100%;
    }

    dd {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @include global.media(touch) {
        width: calc(60% - 30px);
        margin-right: 30px;
        order: 1;

        dl {
            display: flex;
            gap: 20px;
        }

        dt {
            width: 30%;
        }

        dd {
            width: calc(100% - 30% - 20px);
            margin-bottom: 0;
        }
    }
}

.company__about-us__img {
    width: 90%;
    margin: 0 auto;
    order: 1;
    margin-bottom: 30px;

    @include global.media(touch) {
        width: 40%;
        order: 2;
        margin-bottom: 0;
    }
}

.company__about-us__map {
    display: flex;
    justify-content: center;

    iframe {
        width: 90%;
        aspect-ratio: 1 / 1;
    }

    @include global.media(touch) {

        iframe {
            width: 60%;
            aspect-ratio: 6 / 4;
        }
    }
}