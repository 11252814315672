@charset 'utf-8';

/* Assets */
$assets-img-dotted-primary-horizontal: url("./assets/img/bg_dotted_primary-horizontal.svg");
$assets-img-dotted-primary-vertical: url("./assets/img/bg_dotted_primary-vertical.svg");
$assets-img-quote-start: url("./assets/img/bg_quote_start.svg");
$assets-img-quote-end: url("./assets/img/bg_quote_end.svg");

:root {

  /* Font Size */
  --global--font-size-base: 16px;
  --global--font-size-xxxxl: 2.8rem;
  --global--font-size-xxxl: 2.4rem;
  --global--font-size-xxl: 2rem;
  --global--font-size-xl: 1.4rem;
  --global--font-size-lg: 1.15rem;
  --global--font-size-md: 1rem;
  --global--font-size-sm: .9rem;
  --global--font-size-xs: .85rem;
  --global--font-size-xxs: .78rem;
  --global--font-size-xxxs: .7rem;
  --global--font-size-xxxxs: .66rem;

  /* Font Family */
  --global--font-family: Roboto, sans-serif;

  /* Font Weight */
  --global--font-weight-bold: 700;
  --global--font-weight-normal: 400;

  /* Line Height */
  --global--line-height: 1.8;
  --global--line-height-sm: 1.6;

  /* Letter Spacing */
  --global--letter-spacing: .02rem;

  /* Heading */
  --heading--font-size-lg: 3.6rem;
  --heading--font-size-md: 2.8rem;
  --heading--font-size-sm: 2rem;
  --heading--font-family: 'Ubuntu', 'Zen Kaku Gothic Antique';
  --heading--line-height: 1.6;
  --heading--letter-spacing: .02rem;

  /* Color */
  --brand--color-rsv: #4abaa7; // RESERVA Reservation

  --global--color-primary: #C5DD83;
  //--global--color-primary-border: #fdf7f5;
  //--global--color-primary-bg: #fdf7f5; // alpha 10%
  //--global--color-primary-deepest: #d6a916;
  //--global--color-primary-deep: #e0b62a;
  //--global--color-primary-light: #f79d65; // Atomic Tangerine
  //--global--color-primary-lightest: #f7b267; // Merrow Apricot
  --global--color-white: #ffffff;
  --global--color-black: #070707;
  --global--color-text: var(--global--color-black);
  --global--color-text-heading: #271015;
  --global--color-text-highlight: var(--global--color-white);
  --global--color-text-notes: #4d4647;
  --global--color-text-link: var(--global--color-text);
  --global--color-text-link-hover: var(--brand--color-rsv);
  --global--color-border: #bebebe;
  --global--color-border-light: #ececec;
  --global--color-border-highlight: var(--global--color-text-highlight);
  --global--color-bg: var(--global--color-white);
  --global--color-bg-gray: #fafafa;
  --global--color-bg-highlight: var(--global--color-white);
  --global--color-error: #b00020;
  --global--color-error-bg: #fdf0f0;
  --global--color-attention: #e34761;
  --global--color-disabled-text: #727272;
  --global--color-disabled-item: #c3c3c3;
  --global--color-disabled-bg: #fafafa;

  /* Shadow */
  --global--color-shadow: hsla(0, 0%, 0%, .1);
  --global--color-shadow-dark: hsla(0, 0%, 0%, .2);
  --global--shadow: 3px 3px 7px var(--global--color-shadow);
  --global--shadow-dark: 3px 3px 7px var(--global--color-shadow-dark);

  /* Overlay */
  --global--color-overlay: hsla(0, 0%, 0%, .4);
  --global--color-overlay-light: hsla(0, 0%, 100%, .5);

  /* Button */
  --button--padding-v: 12px;
  --button--padding-h: 24px;
  --button--border-width: 1px;
  --button--border-style: solid;
  --button--border-color: var(--global--color-primary);
  --button--bg-color: var(--global--color-bg);

  /* Transition */
  --global--transition-duration: .3s;
  --global--transition-duration-fast: .05s;
  --global--transition-duration-slow: .7s;
  // --global--transition: var(--global--transition-duration) ease;
  // --global--transition-fast: var(--global--transition-duration-fast) linear;
  // --global--transition-slow: var(--global--transition-duration-slow) ease-in-out;

  /* Padding & Margin */
  --global--padding-v: 20px;
  --global--padding-h: 20px;
}