@charset 'utf-8';

@use '../00-settings' as global;

// ----- Styling ---------------------
html, body {
  background-color: var(--global--color-bg);
  height: 100%;
  //&.is-smoothscrollable {
  //  scroll-behavior: smooth;
  //}
}
body {
  position: relative;
  @include global.z-index(reset);
  //&.is-fixed {
  //  position: fixed;
  //  width: 100%;
  //  height: 100%;
  //  overscroll-behavior: none;
  //}
  @include global.media(pc) {
    width: 100%;
    overflow-x: hidden;
  }
}
