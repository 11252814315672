@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - career
 *
 */

.career__main {}

.career__body {}

.career__movie__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.career__movie__container__wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.career__movie {}

.career__movie__wrap {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px;
}

.career__movie__item {
    width: 80%;
    aspect-ratio: 16 / 9;

    @include global.media(touch) {

        width: 45%;
    }
}

.career__movie__youtube {
    position: relative;
    display: block;
    background-color: #000;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.3s;
    }

    i {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 6vw;
        color: #333;
        transition: 0.3s;
    }

    &:hover {

        img {
            opacity: 0.6;

        }

        i {
            color: #eee;
        }
    }

    @include global.media(touch) {

        i {

            font-size: 40px;
        }
    }
}

.career__movie__text {
    margin-top: 10px;
}

.career__img__container {
    margin-top: 50px;
}

.career__img__container__wrapper {}

.career__img {
    img {
        width: 100%;
    }
}

.career__entry__container {
    position: relative;
    width: 100dvw;
    max-width: 100dvw;
    overflow: hidden;
    background: linear-gradient(210deg, #ff8e0c 10%, rgb(255, 181, 21) 90%);
    // background: linear-gradient(210deg, #74a706 10%, rgb(219, 245, 20) 90%);

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        z-index: 0;
        background: linear-gradient(210deg, rgb(254, 208, 24) 10%, #ff853f 90%);
        // background: linear-gradient(30deg, rgb(180, 201, 17) 10%, #98e15c 90%);
        opacity: 0;
        transition: opacity .6s ease-out;
        pointer-events: none;
    }

    .career__entry__link {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 50px calc((100% - 1000px) / 2);

        .career__entry__text {
            position: relative;
            color: #fff;
            font-size: 5rem;
            font-weight: bold;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0 6px;
            margin: 0 40px;

            @media screen and (max-width:1024px) {
                font-size: 4rem;
            }

            @media screen and (max-width:559px) {
                font-size: 2rem;
            }

            .letter {
                transform: scale(1, 1);
                transition: transform .15s ease-in-out;

                @for $i from 1 through 5 {

                    &.l_#{$i} {
                        transition-delay: $i * .05s;
                    }
                }
            }

            .career__entry__arrow {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 70px;
                height: 70px;
                content: "";
                border: 3px solid #fff;
                border-radius: 50%;
                transform: scale(1, 1);
                transition: transform .3s ease-out;

                @media screen and (max-width:1024px) {
                    width: 60px;
                    height: 60px;
                }

                @media screen and (max-width:559px) {
                    width: 50px;
                    height: 50px;
                }

                @media screen and (max-width:399px) {
                    width: 40px;
                    height: 40px;
                }

                .arrow {
                    display: block;
                    width: 8px;
                    height: 13px;
                    background: #fff;
                    clip-path: polygon(0 0, 100% 50%, 0 100%);
                    transform: translateX(2px);
                }
            }
        }
    }

    &:hover {

        &:before {
            opacity: 1;
        }

        .career__entry__text {

            .letter {
                transform: scale(1.09, 1.09);
            }

            .career__entry__arrow {
                transform: scale(1.2, 1.2);
            }
        }
    }
}

// .career__recruiting__container {
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     margin-top: 50px;
//     margin-bottom: 100px;
// }

// .career__recruiting__container__wrapper {
//     width: 100%;
//     max-width: 1000px;
//     margin: 0 20px;

//     @include global.media(touch) {
//         margin: 0 50px;
//     }
// }

// .career__recruiting {}

// .career__recruiting__content {}

// .career__recruiting__item {
//     border-bottom: 3px solid var(--global--color-black);
//     margin-top: 20px;
// }

// .career__recruiting__title {
//     font-size: var(--global--font-size-xl);
//     font-weight: 700;

//     @include global.media(touch) {
//         font-size: var(--global--font-size-xxl);
//     }
// }

// .career__recruiting__link {
//     font-size: var(--global--font-size-lg);
//     margin-right: 50px;
//     margin-bottom: 15px;
//     display: flex;
//     flex-wrap: wrap;
//     column-gap: 60px;

//     i {
//         margin-left: 15px;
//     }

//     @include global.media(touch) {
//         font-size: var(--global--font-size-xl);
//     }
// }