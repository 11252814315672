@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Footer
 *
 */
.footer {
  position: sticky;
  top: 100vh;
  background-color: #2B2B2B;
  color: #fff;
  display: flex;
  justify-content: center;

  a {
    color: #fff;
  }
}

.footer__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: global.$width_pc * 1px;
  margin: 0 50px;
  padding: 50px 0;

  @include global.media(touch) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.footer__link__container {
  font-size: var(--global--font-size-sm);

  a {
    &:hover {
      opacity: 0.6;
    }
  }
}

.footer__copyright {
  font-size: var(--global--font-size-xs);
  margin-top: 30px;

  @include global.media(touch) {
    margin-top: 0;
  }
}