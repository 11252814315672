@charset 'utf-8';

@use '../00-settings'as global;

/**
 *
 * Page - news
 *
 */

//news-list
.news-list__main {}

.news-list__body {}

.news-list__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.news-list__container__wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.news-list {}

.news-list__item {
    display: block;
    border-bottom: 3px solid var(--global--color-black);
    position: relative;
    transition: 0.3s;
    padding: 5px;
    cursor: pointer;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: var(--global--color-black);
    }

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%) rotateZ(-45deg);
        width: 12px;
        height: 12px;
        border-right: 2px solid var(--global--color-white);
        border-bottom: 2px solid var(--global--color-white);
    }

    &:hover {
        color: var(--global--color-black);
        background-color: #ccc;
    }

    @include global.media(touch) {
        &::before {
            width: 40px;
            height: 40px;
        }

        &::after {
            right: 19px;
            width: 16px;
            height: 16px;
        }
    }
}

.news-list__item__date {
    font-size: var(--global--font-size-md);
    margin-top: 10px;

    @include global.media(touch) {
        font-size: var(--global--font-size-lg);
    }
}

.news-list__item__title {
    font-size: var(--global--font-size-xl);
    margin-right: 50px;
    margin-bottom: 15px;

    @include global.media(touch) {
        font-size: var(--global--font-size-xxl);
    }
}

.news-list__year {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    margin-top: 30px;
    margin-bottom: 100px;

    @include global.media(touch) {
        gap: 60px;
        margin-top: 50px;
    }
}

.news-list__year__item {
    font-size: var(--global--font-size-xl);
    font-weight: 700;

    @include global.media(touch) {
        font-size: var(--global--font-size-xxl);
    }
}

//news-detail
.news-detail__main {}

.news-detail__body {}

.news-detail__container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}

.news-detail__container__wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 20px;

    @include global.media(touch) {
        margin: 0 50px;
    }
}

.news-detail {
    margin-bottom: 100px;
}

.news-detail__title {
    font-size: var(--global--font-size-xl);
    font-weight: 700;

    @include global.media(touch) {
        font-size: var(--global--font-size-xxl);
    }
}

.news-detail__date {
    margin-top: 30px;
}

.news-detail__text {
    margin-top: 35px;
    line-height: 1.6;

    a {
        color: var(--global--color-primary);

        &:hover {
            opacity: 0.6;
        }
    }
}

.news-detail__img {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .row {
        display: flex;
        gap: 20px;

        img {
            flex-grow: 1;
        }
    }

    @include global.media(touch) {
        gap: 30px;

        .row {
            gap: 30px;
        }
    }
}

.news-detail__img__item {
    width: 100%;

    img {
        width: 100%;
    }
}

.news-detail__back {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}